<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Resource Folder</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Resource Folder
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn v-if="checkIsAccessible('resource-file', 'create')"
                         @click="createResourceFolder()"
                         class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add Resource Folder
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Name"
                      v-model="search.name"
                      v-on:keyup.enter="searchResource"
                      @input="search.name = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-select
                      label="Visibility"
                      v-model="search.visibility"
                      v-on:keyup.enter="searchResource"
                      @input="search.visibility = $event !== null ? $event : ''"
                      :items="visibilityTypes"
                      item-text="name"
                      item-value="value"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-select
                      label="Status"
                      v-model="search.is_active"
                      v-on:keyup.enter="searchResource"
                      @input="search.is_active = $event !== null ? $event : ''"
                      :items="status"
                      item-text="name"
                      item-value="value"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-btn
                      @click.prevent="searchResource"
                      v-on:keyup.enter="searchResource"
                      class="btn btn-primary"
                      :loading="loading"
                  >
                    <v-icon small outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <template>

                <draggable class="w-100" v-model="resourceFolders" @change="sort" @start="drag=true" tag="div"
                           @end="drag=false">
                  <v-row v-if="!loading">
                    <v-col cols="12" md="2" v-for="(resourceFolder, index) in resourceFolders" :key="index">
                      <div class="cursor-pointer">
                        <v-img
                            @click="goToFile(resourceFolder.id)"
                            :lazy-src="require('@/assets/media/folder.png')"
                            max-height="150"
                            max-width="150"
                            :src="require('@/assets/media/folder.png')"
                        >
                        </v-img>
                        <v-btn
                            class="deleteBtn"
                            @click="deleteResource(resourceFolder.id)"
                            absolute
                            rounded
                            right
                            top
                        >
                          <v-icon small color="red">mdi-delete</v-icon>
                        </v-btn>
                        <h1 class="ml-3">{{ resourceFolder.title }}</h1>
                      </div>
                    </v-col>
                  </v-row>
                </draggable>

                <div v-if="resourceFolders.length == 0">
                  <span class="text-center font-weight-bold px-3">No Data Found</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllResourceFolders"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import draggable from "vuedraggable";
import CreateAndUpdate from "@/view/pages/view/cms/resource/folder/CreateAndUpdate";
import ResourceFolderService from "@/services/cms/resource/Folder/ResourceFolderService";

const resourceFolder = new ResourceFolderService();
export default {
  components: {
    CreateAndUpdate,
    draggable
  },
  data() {
    return {
      drag: false,
      resourceFolders: [],
      total: null,
      perPage: null,
      page: null,
      loading: false,
      isSorting: false,
      visibilityTypes: [
        {name: 'Everyone', value: 'everyone'},
        {name: 'Custom', value: 'custom'},
      ],
      status: [
        {name: 'All', value: ''},
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      search: {
        name: '',
        visibility: '',
        is_active: '',
      }
    }
  },
  mounted() {
    this.getAllResourceFolders();
  },
  methods: {
    createResourceFolder() {
      this.$refs['create-and-update'].createResourceFolder();
    },
    editResourceFolder(faq) {
      this.$refs['create-and-update'].editResourceFolder(faq);
    },
    getAllResourceFolders() {
      this.loading = true;
      resourceFolder
          .paginate(this.search, this.page)
          .then(response => {
            this.resourceFolders = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    goToFile(folderId) {
      if (this.checkIsAccessible('resource-file', 'show')){
        this.$router.push({
          name: "resource-file",
          params: {folderId: folderId}
        })
      }
    },
    sort() {
      this.isSorting = true;
      resourceFolder
          .sort(this.resourceFolders)
          .then(response => {
            this.isSorting = false;
            this.$snotify.success('Faq Sorted !!');
            this.getAllResourceFolders();
          })
          .catch(err => {
            this.isSorting = false;
            this.$snotify.error("Oops something went wrong");
          });
    },

    searchResource() {
      this.page = 1;
      this.getAllResourceFolders();
    },
    deleteResource(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            resourceFolder
                .delete(id)
                .then((response) => {
                  this.getAllResourceFolders()
                  this.$snotify.success("Resource Folder Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    }
  }
}
</script>
<style lang="scss">
.deleteBtn {
  min-width: 36px !important;
  padding: 0 !important;
}
</style>