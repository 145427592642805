<template>
  <v-dialog
      v-model="dialog"
      max-width="600"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Resource Folder</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="resource_folder.title"
                  :error="$v.resource_folder.title.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.resource_folder.title.$error">Title is required</span>
              <span class="text-danger" v-if="errors.title" >* {{ errors.title[0] }}</span>
            </v-col>

            <v-col cols="12">
              Visible To Applicant
              <v-switch
                  v-model="resource_folder.visible_to_applicant"
                  :label="resource_folder.visible_to_applicant ? 'Yes' : 'No'"
              ></v-switch>
            </v-col>

            <v-col cols="12" v-if="resource_folder.visible_to_applicant">
              <v-select
                  item-text="title"
                  item-value="id"
                  :items="services"
                  v-model="resource_folder.service_id"
                  :error="$v.resource_folder.service_id.$error"
                  @input="resource_folder.service_id = $event !== null ? $event : ''"
                  small-chips
                  deletable-chips
                  outlined
                  dense
              >
                <template v-slot:label>
                  Service <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.resource_folder.service_id.$error">Service is required</span>
              <span class="text-danger" v-if="errors.service_id" >* {{ errors.service_id[0] }}</span>
            </v-col>


            <v-col cols="12">
              <v-select
                  v-model="resource_folder.visibility"
                  :error="$v.resource_folder.visibility.$error"
                  @input="resource_folder.visibility = $event !== null ? $event : ''"
                  :items="visibilityTypes"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Visibility <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.resource_folder.visibility.$error">Visibility is required</span>
              <span class="text-danger" v-if="errors.visibility" >* {{ errors.visibility[0] }}</span>
            </v-col>


            <v-col cols="12" v-if="selectedUsers.length > 0">
              <v-chip class="ma-2" @click:close="removeUser(index)" color="primary"
                      v-for="(examiner,index) in selectedUsers" :key="index"
                      label close>
                {{ examiner }}
              </v-chip>
            </v-col>

            <v-col cols="12" v-if="resource_folder.visibility == 'custom'">
              <v-autocomplete
                  @change="getUser"
                  v-model="selectedUser"
                  :search-input.sync="userSearch"
                  :items="items"
                  :loading="isLoading"
                  item-text="display_text"
                  item-value="id"
                  label="Users"
                  return-object
                  hide-selected
                  outlined
                  dense
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>Please Type Email, First Name or Unique Identifier</strong>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.resource_folder.user_ids.$error">User is required</span>
              <span class="text-danger" v-if="errors.user_ids" >* {{ errors.user_ids[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              Status
              <v-switch
                  v-model="resource_folder.is_active"
                  :label="resource_folder.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            @click="closeDialog"
        >
          Cancel
        </v-btn>

        <v-btn
            class="btn btn-primary"
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import ServiceService from "@/services/service/ServiceService";
import UserService from "@/services/user/UserService";
import ResourceFolderService from "@/services/cms/resource/Folder/ResourceFolderService";

const service = new ServiceService();
const user = new UserService();
const resourceFolder = new ResourceFolderService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      title: '',
      edit: false,
      dialog: false,
      loading: false,
      isLoading: false,
      userSearch: null,
      errors: [],
      services: [],
      users: [],
      selectedUsers: [],
      selectedUser: {},
      visibilityTypes: [
        { name: 'Everyone', value: 'everyone'},
        { name: 'Custom', value: 'custom'},
      ],
      resource_folder: {
        title: '',
        visible_to_applicant: false,
        service_id: '',
        visibility: '',
        user_ids: [],
        is_active: true,
      },

      editorConfig: {
        versionCheck: false,
        toolbar: [
            [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' , 'Source','-','Save','NewPage','DocProps','Preview','Print','-','Templates'],
        ]
      }
    }
  },
  validations: {
    resource_folder: {
      title: { required },
      service_id: { required: requiredIf(function () {
          return this.visible_to_applicant;
        }) },
      visibility: { required },
      user_ids: { required: requiredIf(function () {
          return this.resource_folder.visibility === 'custom'
        }) },
      is_active: { required }
    }
  },
  watch: {
    userSearch (val) {
      if (this.isLoading) return;
      if(val != null) {
        user
            .getAllByUserType({name: val})
            .then((response) => {
              this.users =response.data.users
            })
            .catch(err => {
              // console.log(err)
            })
            .finally(() => (this.isLoading = false))
      }
    }
  },
  mounted() {
    this.getAllServices();
  },
  computed: {
      items() {
        return this.users.map((user, index) => {
          const display_text = user.first_name+' '+ user.last_name+ ' || '+user.email+' || '+user.unique_identifier;
          return Object.assign({}, user, { display_text });
        });
      }
  },
  methods: {
    closeDialog(){
      this.dialog = false;
    },
    createResourceFolder(){
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editResourceFolder(resource_folder){
      this.title = "Edit"
      this.dialog = true;
      this.edit = true;
      this.resource_folder = resource_folder;
    },
    getAllServices() {
      service.all().then(response => {
        this.services = response.data.services;
      });
    },
    getUser(){
      if(this.resource_folder.user_ids == null || this.resource_folder.user_ids == ''){
        this.resource_folder.user_ids = [];
      }
      if(this.selectedUser != null) {
        let full_name = this.selectedUser.first_name + ' '+ this.selectedUser.last_name;
        this.resource_folder.user_ids.push(this.selectedUser.id);
        this.selectedUsers.push(full_name);
      }

      this.selectedUser = null;
      this.userSearch = null;
      this.users = [];
    },
    removeUser(index){
      this.resource_folder.user_ids.splice(index,1);
      this.selectedUsers.splice(index,1);
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function() {
      resourceFolder
          .create(this.resource_folder)
          .then(response =>{
            this.loading = false;
            this.$snotify.success("Resource Folder created successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      resourceFolder
          .update(this.resource_folder.id,this.resource_folder)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Resource Folder updated successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    resetForm(){
      this.$v.$reset();
      this.selectedUser = [];
      this.selectedUsers = [];
      this.resource_folder = {
        title: '',
        visible_to_applicant: false,
        service_id: '',
        visibility: '',
        user_ids: [],
        is_active: true,
      }
    }
  }
}
</script>

<style scoped>

</style>
